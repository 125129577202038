'use client';
import { useState, useEffect } from 'react';

const src =
  'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2254.1595975076134!2d37.598765077079285!3d55.599236003808635!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x414ab27bc2de2a0d%3A0x2603a7137a0acbec!2z0JLQsNGA0YjQsNCy0YHQutC-0LUg0YguLCAxNTAg0YHRgtGA0L7QtdC90LjQtSAyLCDQnNC-0YHQutCy0LAsINCg0L7RgdGW0Y8sIDExNzUzNA!5e0!3m2!1suk!2sua!4v1698742904073!5m2!1sru!2sen';
const GoogleMapIframe = () => {
  const [showIframe, setShowIframe] = useState(false);

  useEffect(() => {
    const handleUserAction = () => {
      setShowIframe(true);

      // Удаляем обработчики после первой загрузки
      document.removeEventListener('click', handleUserAction);
      document.removeEventListener('keydown', handleUserAction);
      document.removeEventListener('mousemove', handleUserAction);
      document.removeEventListener('scroll', handleUserAction);
      document.removeEventListener('touchstart', handleUserAction);
    };

    // Добавляем обработчики событий
    document.addEventListener('click', handleUserAction);
    document.addEventListener('keydown', handleUserAction);
    document.addEventListener('mousemove', handleUserAction);
    document.addEventListener('scroll', handleUserAction);
    document.addEventListener('touchstart', handleUserAction);

    // Очистка при размонтировании компонента
    return () => {
      document.removeEventListener('click', handleUserAction);
      document.removeEventListener('keydown', handleUserAction);
      document.removeEventListener('mousemove', handleUserAction);
      document.removeEventListener('scroll', handleUserAction);
      document.removeEventListener('touchstart', handleUserAction);
    };
  }, []);
  return (
    <div style={{ width: '100%', height: '500px' }}>
      {showIframe && (
        <iframe
          src={src}
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{ width: '100%', height: '100%', border: 'none' }}
          allowFullScreen=""
        />
      )}
    </div>
  );
};

export default GoogleMapIframe;
