'use client';

import { createContext, useContext, useEffect, useState } from 'react';
import * as socketIO from 'socket.io-client';
// import { useUser } from "./UserContext";
// import { useRouter } from "next/navigation";
// const apiUrl = process.env.SERVER_URL;

const intialData = {
  socket: undefined,
  roomUsers: {},
  messages: {},
};

const SocketContext = createContext(intialData);

export function useSocket() {
  return useContext(SocketContext);
}

export default function SocketProvider({ children }) {
  const [roomUsers, setRoomUsers] = useState({});
  const [socket, setSocket] = useState();
  const [messages, setMessages] = useState({});
  const [user] = useState(() => {
    const user = window.localStorage.getItem('cht');

    if (user) {
      return user;
    }
    const newUser = generateUniqueID();

    window.localStorage.setItem('cht', newUser);
    return newUser;
  });
  //   const { username } = useUser();
  //   const router = useRouter();

  useEffect(() => {
    // if (!username) {
    //   router.replace("/");
    //   return;
    // }

    // let socket = socketIO.connect("http://localhost:1488");
    let socket = socketIO.connect('https://api.auto-desk.pro');
    socket.emit('connect_user', user);
    socket.on('receive_message', (data) => {
      setMessages((prev) => {
        const newMessages = { ...prev };
        newMessages[data.roomId] = [...(newMessages[data.roomId] ?? []), data];
        return newMessages;
      });
    });
    socket.on('users_response', (data) => setRoomUsers(data));
    setSocket(socket);

    return () => {
      socket.disconnect(user);
    };
  }, []);

  return (
    <SocketContext.Provider value={{ socket, roomUsers, messages }}>
      {children}
    </SocketContext.Provider>
  );
}

function generateUniqueID() {
  const timestamp = Date.now().toString(36); // Используем текущее время в виде строки
  const randomPart = Math.random().toString(36).substr(2, 5); // Генерируем случайную часть

  return timestamp + randomPart;
}
