'use client';

import VkSvg from '@/svg/socials/vk.svg';
import WhatsAppSvg from '@/svg/socials/whatsapp.svg';
import YouTubeSvg from '@/svg/socials/youtube.svg';
import InstaSvg from '@/svg/socials/insta.svg';
import TikTokSvg from '@/svg/socials/tiktok.svg';
import FbSvg from '@/svg/socials/fb.svg';
import TelegaSvg from '@/svg/socials/telega.svg';
import { useEffect, useState } from 'react';

export default function Socials() {
  const [data, setDate] = useState([]);
  useEffect(() => {
    async function fetchContacts() {
      const url = '/api/contacts';
      const response = await fetch(url);
      const data = await response.json();
      if (!data) return;
      setDate(data.socialsData);
    }
    fetchContacts();
  }, []);
  const list = data.map((item) => {
    const icon = getIcon(item.type);
    return (
      <li key={item._id}>
        <a href={item.link} target="_blank">
          {icon}
        </a>
      </li>
    );
  });
  return <ul className="socials">{list}</ul>;

  function getIcon(type) {
    switch (type) {
      case 'TikTok':
        return <TikTokSvg />;
      case 'VK':
        return <VkSvg />;
      case 'FB':
        return <FbSvg />;
      case 'WhatsApp':
        return <WhatsAppSvg />;
      case 'YouTube':
        return <YouTubeSvg />;
      case 'Telegram':
        return <TelegaSvg />;
      case 'Instagram':
        return <InstaSvg />;
      default:
        return '❓'; // Return a question mark for unknown types
    }
  }
}
